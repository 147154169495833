@import url('https://fonts.googleapis.com/css?family=Abel|Raleway|Raleway+Dot|Asap|Barlow|Glegoo|Julius+Sans+One|Lato|Muli|Poiret+One|Sulphur+Point|Turret+Road&display=swap');

.main_row{
    font-family: 'Sulphur Point', sans-serif;
}
.card_col{
    padding-left: 4%;
    padding-top: 4%;
}

.card_main{
    background: rgba(255, 255, 255, 0.1);
    color: white;
    border-color: white;
    text-align: right;
}

.card_main:hover{
   transition: all 0.2s ease-out;
   top: -4px;
   background: rgba(255, 255, 255, 0.15);
   box-shadow: 0px 4px 35px rgb(255, 255, 255);
}

.blockquote-footer{
    color: white;
}

.icon{
    color: white;
    cursor: pointer;
}

.icon:hover{
    color: rgb(255, 196, 102);
 }